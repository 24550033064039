import React, { useState, useRef, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import './App.css';
import Wheel from '../Wheel/Wheel';

const tg = window.Telegram.WebApp;
      
const App = () => {

  return (
    <div id="app">
      {/* <Routes> */}
        {/* <Route path='/wheel/:id'element={<Wheel />}/> */}
      {/* </Routes> */}
      {/* <Wheel name={tg.initDataUnsafe.start_param}/> */}
      <Wheel name="TEST"/>
    </div>
  );
};

export default App;
